import logo from '../logo.svg';
import { useCallback, useEffect, useState } from 'react';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Button, Grid, Card, TextField, InputAdornment, IconButton, Stack, RadioGroup, Radio, Typography } from "@mui/material";
import { useForm, Controller, Form as FormHook } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import MenuItem from '@mui/material/MenuItem';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import InputMask from 'react-input-mask';
import TelephoneMasked from '../components/TextFieldTelePhone';
import { baseURL } from '../config/api';


function Form() {

    const { handleSubmit, control, register, formState: { isSubmitting, errors } } = useForm({ mode: 'all' });

    const [questions, setQuestions] = useState();
    const [quizId, setQuizId] = useState();
    const [qtdInputs, setQtdInputs] = useState(1);
    const [telefones, setTelefones] = useState([{}]);
    const [clientId, setClientId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [update, setUpdate] = useState(false);
    const navigate = useNavigate();


    function FormattedInput(props) {
        const { inputRef, ...other } = props;

        return (
            <InputMask
                {...other}
                ref={inputRef}
                mask="(99) 99999-9999"
                placeholder="CPF"
                defaultValue={''}
            />
        );
    }


    const getFormInputs = (idx) => {
        const minWidth = telefones[idx]?.type === 'TRABALHO' || telefones[idx]?.type === 'OUTRO' ? 14 : 15;
        return (
            <Stack key={`Telefones${idx}`} direction='row' spacing={1}>
                <TextField
                    key={`tipoTelefone${idx}`}
                    select
                    value={telefones[idx]?.type ?? 'CELULAR'}
                    style={{ flex: 3 }}
                    onChange={(e) => setTelefones(prevState => {
                        const temp = prevState;
                        temp[idx]['type'] = e.target.value.toUpperCase();
                        setUpdate(prevState => !prevState);
                        return temp;
                    })}
                    variant="outlined" label='Tipo'
                >
                    {['CELULAR', 'TRABALHO', 'OUTRO'].map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>


                <Controller
                    key={`telefone${idx}`}
                    name={`telefone${idx}`}
                    control={control}
                    defaultValue=""
                    rules={{ required: true, minLength: minWidth }}
                    onChange={(_) => setUpdate(prevState => !prevState)}
                    render={({ field }) => (
                        <TelephoneMasked
                            key={`telefone${idx}`}
                            required={true}
                            style={{ flex: 9 }}
                            type={telefones[idx]?.type ?? 'CELULAR'}
                            {...field}
                            label="Qual o seu telefone?"
                            variant="outlined"
                            error={!!errors[`telefone${idx}`]}
                            helperText={errors[`telefone${idx}`]?.message || ''}
                        />
                    )}
                />
            </Stack>
        );
    }


    const getInputs = useCallback(() => {
        let inputListTemp = [];
        for (let i = 0; i < qtdInputs; i++) {
            inputListTemp.push(getFormInputs(i));
        }
        return inputListTemp;
    }, [qtdInputs, update])

    useEffect(() => {
        fetch(`${baseURL}/api/quizzes/last`).then(e => e.json()).then(e => {
            setQuizId(e?.quiz?.id);
            setQuestions(e?.questions);
        });
    }, [])

    const notify = (message) => toast(message, { position: 'bottom-right', type: 'error' });

    const onSubmit = async (data) => {

        if (!clientId) {

            const { name, email, institution } = data;
            let resultClient = null;
            let statusResponse = null;
            try {
                const response = await fetch(`${baseURL}/api/clients`, {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        name: name,
                        email: email,
                        institution: institution,
                    })
                });

                statusResponse = response.status;

                if (response.ok) {
                    resultClient = await response.json();
                }
                else {
                    const error = await response.json();
                    throw new Error(error?.message);
                }
            } catch (e) {
                notify(e?.message ?? 'Algo deu errado!');
            }

            if (statusResponse === 201) {
                const telefonesFinal = telefones.map((e, index) => {
                    let tel = {};
                    tel['telephone'] = data[`telefone${index}`];
                    tel['clientId'] = resultClient.id
                    if (e?.type === undefined) {
                        tel['type'] = 'CELULAR';
                    } else {
                        tel['type'] = e?.type;
                    }
                    return tel;
                });

                for (const telFinal of telefonesFinal) {
                    try {
                        const resultTelephone = await fetch(`${baseURL}/api/telephones`, {
                            method: "POST",
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(telFinal)
                        });

                        if (!resultTelephone.ok) {
                            throw new Error('Algo deu errado!');
                        }
                    } catch (_) {

                    }
                }

            }
            if(statusResponse === 200 || statusResponse === 201){
                setClientId(resultClient.id);
            }
        } else {
            const answerValues = [];
            Object.keys(data).forEach(e => {
                if (data[e] === undefined) {
                    data[e] = false;
                }
                if (e.startsWith('answer')) {
                    answerValues.push(data[e]);
                }
            });
            const dataAnswers = {
                clientId: clientId,
                quizId: quizId,
                answers: answerValues
            };

            let resultQuizClient = null;

            try {
                const response = await fetch(`${baseURL}/api/answers`, {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(dataAnswers)
                });
                if (response.ok) {
                    resultQuizClient = await response.json();
                    window.scrollTo(0, 0);
                    navigate(`/result?id=${resultQuizClient?.id}`);
                } else {
                    throw new Error('Algo deu errado!');
                }
            } catch (e) {
                notify(e.message);
            }
        }
    };

    let title = '';

    return (
        <div style={{ background: '#f3f4f6' }}>
            <Grid>

                <Grid container justifyContent='center'>
                    <Button href="https://consultmidia.it/" >
                        <img src="/logo.png" alt="Logo" />
                    </Button>
                </Grid>

                <Grid container justifyContent='center'>
                    <Card style={{ padding: 40, borderRadius: '.5rem', width: '640px' }}>
                        <Grid container style={{ flex: 1, justifyContent: 'flex-start' }}>

                            <FormControl component="fieldset" fullWidth>
                                <FormHook onSubmit={handleSubmit(onSubmit)} control={control}>
                                    {!clientId ?
                                        <FormGroup>
                                            <Controller
                                                name={`name`}
                                                control={control}
                                                rules={{
                                                    required: true, maxLength: 255, pattern: {
                                                        value: /^[a-záàâãéèêíïóôõöúçñ ]+$/i,
                                                        message: 'Digite um nome válido'
                                                    }
                                                }}
                                                render={({ field: props }) => (
                                                    <TextField {...props}
                                                        required={true}
                                                        value={props.value ?? ''}
                                                        error={!!errors.name}
                                                        helperText={errors.name?.message || ''}
                                                        onChange={(e) => props.onChange(e.target.value.toUpperCase())}
                                                        variant="outlined" label='Qual o seu nome?'
                                                    />
                                                )}
                                            />
                                            <div style={{ paddingBottom: '8px' }}></div>
                                            <Controller
                                                name={`email`}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                    maxLength: 255,
                                                    pattern:
                                                    {
                                                        value: /^[a-z0-9.]+@[a-z]+\.[a-z]+(\.[a-z]+)?$/i,
                                                        message: 'Digite um email válido'
                                                    }
                                                }}
                                                render={({ field: props }) => {

                                                    return (
                                                        <TextField {...props}
                                                            required={true}
                                                            value={props.value ?? ''}
                                                            error={!!errors.email}
                                                            helperText={errors?.email?.message || ''}
                                                            onChange={(e) => props.onChange(e.target.value.toLowerCase())}
                                                            variant="outlined" label='Qual o seu e-mail?'
                                                        />
                                                    )
                                                }
                                                }
                                            />
                                            <div style={{ paddingBottom: '8px' }}></div>
                                            <Controller
                                                name={`institution`}
                                                control={control}
                                                rules={{ required: true, maxLength: 255 }}
                                                render={({ field: props }) => (
                                                    <TextField {...props}
                                                        required={true}
                                                        value={props.value ?? ''}
                                                        error={!!errors.institution}
                                                        helperText={errors?.institution?.message || ''}
                                                        onChange={(e) => props.onChange(e.target.value.toUpperCase())}
                                                        variant="outlined" label='Qual o nome da sua organização?'
                                                    />
                                                )}
                                            />
                                            <div key={'div'} style={{ paddingBottom: '8px' }}></div>
                                            {<Stack key={'stackTelefone'} spacing={1}>
                                                {getInputs()}
                                            </Stack>
                                            }
                                            <Grid container justifyContent='flex-end'>
                                                <IconButton color='primary' onClick={async () => {
                                                    setTelefones(prevState => {
                                                        const temp = prevState;
                                                        temp.push({});
                                                        return temp;
                                                    })
                                                    setQtdInputs(prevState => prevState + 1);
                                                }}>
                                                    <AddIcon />
                                                </IconButton>
                                            </Grid>
                                        </FormGroup> :
                                        <FormGroup>
                                            {
                                                questions?.map((questFinal, idxQuestion) => {
                                                    let showTitle = false;
                                                    if (title !== questFinal.question.value) {
                                                        title = questFinal.question.value;
                                                        showTitle = true;
                                                    } else {
                                                        showTitle = false;
                                                    }

                                                    return (<>
                                                        {showTitle && <Typography variant="h6" gutterBottom>
                                                            {title}
                                                        </Typography>}
                                                        <Controller
                                                            key={idxQuestion}
                                                            name={`answer${idxQuestion}`}
                                                            control={control}
                                                            rules={{ required: true }}
                                                            render={({ field: props }) => (
                                                                <FormControl key={idxQuestion} error={!!errors[`answer${idxQuestion}`]}>
                                                                    <FormLabel>{`${(idxQuestion + 1) + ' - ' + questFinal?.question?.question}`}</FormLabel>
                                                                    <RadioGroup key={idxQuestion}
                                                                        onChange={(selected) => props?.onChange(selected)}
                                                                    >
                                                                        {questFinal?.questionOptions?.map((opt, idxOpt) => (
                                                                            <FormControlLabel key={idxOpt} value={opt.value} label={opt.label} control={<Radio />} />
                                                                        ))
                                                                        }

                                                                    </RadioGroup>
                                                                </FormControl>
                                                            )}
                                                        />
                                                    </>
                                                    )
                                                })
                                            }
                                        </FormGroup>
                                    }

                                    <Grid container style={{ alignContent: 'center', flexDirection: 'column' }}>
                                        <Grid item width='40%'>
                                            <Button variant="contained" type='submit' color="primary" disabled={isSubmitting} fullWidth>
                                                {!clientId ? 'Próximo' : 'Enviar'}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </FormHook>
                            </FormControl>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
            <ToastContainer />
        </div>
    );
}

export default Form;