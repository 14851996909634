import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, Grid, Card, Stack, Container, Typography, Box, List, ListItem, ListItemText } from "@mui/material";
import Chart from "react-apexcharts";
import { getPositiveText, getResultIntro, getTextConclusao, getTextMelhoria } from '../utils/ResultText';
import { baseURL } from '../config/api';


function Result() {

  const [resultNivel, setResultNivel] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [rank, setRank] = useState([]);
  const [nameInstitution, setNameInstitution] = useState('');
  const [nivelOriginal, setNivelOriginal] = useState(0);
  const [colorsPyramid, setColorsPyramid] = useState([
    '#888888',
    '#888888',
    '#888888',
    '#888888',
    '#888888',
    '#888888',
    '#888888'
  ]);




  useEffect(() => {
    fetch(`${baseURL}/api/results?id=${searchParams.get("id")}`).then(e => e.json()).then(e => {
      setNameInstitution(e?.client?.institution);
      let soma = 0;
      const rankFinal = rankAnswers(e?.questions, e?.answers);
      console.log(rankFinal);
      setRank(rankFinal);
      e?.answers?.forEach(element => {
        soma += Number(element.answer);
      });
      let nivelDig = Math.floor(((soma / 23) * 7));
      setNivelOriginal(nivelDig);
      if (nivelDig === 0) {
        nivelDig = 1;
      }
      setColorsPyramid(prevState => {
        let temp = prevState;
        for (let i = 6; i >= (7 - nivelDig); i--) {
          temp[i] = '#00BB00';
        }
        return temp;
      });
      setResultNivel(nivelDig);
    });
  }, [])

  const rankAnswers = (questions, answers) => {
    const result = Object.groupBy(questions, ({ value }) => value);
    let resultSomado = [];
    Object.keys(result).forEach(e => {
      let soma = 0
      result[e].forEach(quest => {
        const value = answers.find(ans => ans?.order === quest?.order)?.answer;
        soma += Number(value);
      });
      const resultFinal = {};
      resultFinal['categoria'] = e;
      resultFinal['value'] = soma;
      resultSomado.push(resultFinal);
    });
    resultSomado.sort((a, b) => a?.value - b?.value);
    return resultSomado;
  }

  const labelsPyramid = {
    2.2: 'Transformação Digital Total',
    3: 'Integração de IA',
    4: 'Dados e Análises',
    5: 'Digitalização',
    6: 'Automação Básica',
    7: 'Conectado',
    8: 'Inicial',
  };

  const labelsNivel = {
    7: 'Transformação Digital Total',
    6: 'Integração de IA',
    5: 'Dados e Análises',
    4: 'Digitalização',
    3: 'Automação Básica',
    2: 'Conectado',
    1: 'Inicial',
  };

  return (
    <div style={{ background: '#f3f4f6' }}>
      <Grid>

        <Grid container justifyContent='center'>
          <Button href="https://consultmidia.it/" >
            <img src="/logo.png" alt="Logo" />
          </Button>
        </Grid>

        <Grid container justifyContent='center'>
          <Card style={{ padding: 40, borderRadius: '.5rem', width: '640px' }}>
            <Grid container style={{ flex: 1, justifyContent: 'center' }}>
              {resultNivel &&
                <Chart
                  series={[{
                    name: '',
                    data: [2.2, 3, 4, 5, 6, 7, 8]
                  }]}
                  width={420}
                  options={{
                    chart: {
                      toolbar: { show: false }
                    },
                    plotOptions: {
                      bar: {
                        horizontal: true,
                        isFunnel: true,
                        distributed: true
                      }
                    },
                    tooltip: {
                      y: {
                        formatter: (e) => ''
                      }
                    },
                    colors: colorsPyramid,
                    xaxis: {
                      categories: [
                        'Nível 7 - Transformação Digital Total',
                        'Nível 6 - Integração de IA',
                        'Nível 5 - Dados e Análises',
                        'Nível 4 - Digitalização',
                        'Nível 3 - Automação Básica',
                        'Nível 2 - Conectado',
                        'Nível 1 - Inicial'
                      ]
                    },
                    dataLabels: {

                      enabled: true,
                      formatter: (e, opts) => {
                        if (opts.dataPointIndex === 6 - (resultNivel - 1)) {
                          return labelsPyramid[e];
                        } else {
                          return '';
                        }
                      },
                      style: {
                        fontSize: '8px',
                        colors: ['#fff']
                      }
                    },
                  }


                  }

                  type='bar'
                />

              }

              <Container>
                <center>
                  <Typography variant="h6" gutterBottom>
                    Resultado da Análise:
                  </Typography>
                </center>
                <center>
                  <Typography variant="h4" gutterBottom>
                    {labelsNivel[resultNivel]}
                  </Typography>
                </center>
                <Typography variant="body1" gutterBottom>
                  O resultado da análise de digitalização revelou que {nameInstitution} se encontra no {getResultIntro(resultNivel)}
                </Typography>

                <Box my={4}>
                  <Typography variant="h6" gutterBottom>
                    Pontos Positivos:
                  </Typography>
                  <List>
      {
                    nivelOriginal===0 ?
                    <ListItem>
                      <ListItemText primary="A empresa ainda não possui práticas digitais implementadas." />
                    </ListItem>
                    :
                    rank.slice(-3)?.map((category) => {
                      const categoryLabel = category?.categoria;
                      if (category.value === 0) {
                        return null
                      }
                      return getPositiveText(categoryLabel)?.map((textResult, idxText) => {
                        const primaryText = idxText === 0 ? categoryLabel : '';
                        return (
                          <ListItem key={`${categoryLabel}${idxText}`}>
                            <ListItemText primary={primaryText} secondary={textResult} />
                          </ListItem>
                        )
                      });
                    })}
                  </List>
                </Box>

                <Box my={4}>
                  <Typography variant="h6" gutterBottom>
                    Áreas de Melhoria:
                  </Typography>
                  <List>
                    {rank.slice(0, 3)?.map((category) => {
                      const categoryLabel = category?.categoria;
                      return getTextMelhoria(categoryLabel)?.map((textResult, idxText) => {
                        const primaryText = idxText === 0 ? categoryLabel : '';
                        return (
                          <ListItem key={`melhoria${categoryLabel}${idxText}`}>
                            <ListItemText primary={primaryText} secondary={textResult} />
                          </ListItem>
                        )
                      });
                    })}

                  </List>
                </Box>

                <Typography variant="body1">
                  {getTextConclusao(resultNivel)}
                </Typography>
              </Container>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default Result;